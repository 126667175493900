// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define a light theme
$light-primary: mat.define-palette(mat.$indigo-palette);
$light-accent: mat.define-palette(mat.$blue-grey-palette);
$light-background: mat.get-color-from-palette($light-accent, 200);
$light-theme: mat.define-light-theme((
 color: (
   primary: $light-primary,
   accent: $light-accent,
 )

));

// Define a dark theme
$dark-primary: mat.define-palette(mat.$indigo-palette);
$dark-accent: mat.define-palette(mat.$blue-grey-palette);
$dark-theme: mat.define-dark-theme((
 color: (
   primary: $dark-primary,
   accent: $dark-accent,
 ),
));



// checks system preference of user device:
// if light, then activate light scheme
// @media (prefers-color-scheme: light) {
//  @include mat.core-color($light-theme);
//  @include mat.button-color($light-theme);
//  @include mat.all-component-colors($light-theme);
//}


// checks system preference of user device:
// if dark, then activate dark scheme
//@media (prefers-color-scheme: dark) {
//  @include mat.core-theme($dark-theme);
//  @include mat.button-theme($dark-theme);
//  @include mat.all-component-themes($dark-theme);
//}

@include mat.core-color($light-theme);
@include mat.button-color($light-theme);
@include mat.all-component-colors($light-theme);

/* You can add global styles to this file, and also import other style files */


html, body { 
  height: 100%;
  margin: 0;
  padding: 0;
}
body { 
  margin: 0;
  font-family: Quicksand, sans-serif;
}


// this is to to disable text selecting on desired areas
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                      supported by Chrome, Opera and Firefox */
}

// hides scrollbar
::-webkit-scrollbar {
  width: 0;
  background: transparent; /* make scrollbar transparent */
}


.centerelement {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-flow: row wrap;
}

.layoutRowSpaceAround {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; 
  justify-content:space-around;
}